import React, { Component } from 'react';
import {
  Grow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Snackbar,
  CircularProgress,
  CardActions,
  Button,
  Popper,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Left from '@material-ui/icons/NavigateBefore';
import Right from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import config from '../config.json';

export default class Career extends Component {
  constructor() {
    super();
    this.state = {
      careers: [],
      indexOfCareer: 0,
      isSnackBarOpen: false,
      fetchingCareerDetails: true,
      openPopper: false,
      anchorEl: null,
      applyClicked: false,
      userDetails: {
        name: '',
        jobPost: '',
        email: '',
        number: '',
        experienceInYears: '',
        experienceInMonths: '',
        resume: null,
        resumeFilename: '',
      },
      sendingMail: false,
      snackbarMessage: '',
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${config.apiPrefix}/careers`);
      this.setState({
        careers: response.data,
      });
    } catch (e) {
      this.setSnackbarParams(true, "Couldn't fetch career details!!");
    } finally {
      this.setState({
        fetchingCareerDetails: false,
      });
    }
  }

  handleArrowClick = direction => {
    let incrementValue = 0;
    const { careers } = this.state;
    let { indexOfCareer } = this.state;
    const lengthofCareers = careers.length;
    if (lengthofCareers > 3) {
      if (direction === 'right') {
        incrementValue = 3;
      }
      if (direction === 'left') {
        incrementValue = -3;
      }
      indexOfCareer += incrementValue;
      if (indexOfCareer >= lengthofCareers) {
        indexOfCareer = 0;
      }
      if (indexOfCareer < 0) {
        indexOfCareer = lengthofCareers - 1;
      }
    }
    this.setState({
      indexOfCareer,
    });
  };

  setSnackbarParams = (isSnackBarOpen, snackbarMessage = '') => {
    this.setState({
      isSnackBarOpen,
      snackbarMessage,
      sendingMail: false,
      applyClicked: false,
    });
  };

  togglePopper = event => {
    event.persist();
    this.setState(prevState => ({
      openPopper: !prevState.openPopper,
      anchorEl: event.target,
    }));
  };

  onChange = stateKey => event => {
    const parentStateKey = stateKey.split('.')[0];
    const childStateKey = stateKey.split('.')[1];
    const currentState = this.state;
    currentState[parentStateKey][childStateKey] = event.target.value;
    this.setState({
      ...currentState,
      applyClicked: false,
    });
  };

  onSend = async () => {
    this.setState({
      applyClicked: true,
      sendingMail: true,
    });
    const { userDetails } = this.state;
    const {
      name,
      email,
      number,
      experienceInMonths,
      experienceInYears,
      jobPost,
      resume,
      resumeFilename,
    } = userDetails;
    const hasNoError =
      name &&
      email &&
      number &&
      (experienceInYears || experienceInMonths) &&
      jobPost &&
      resume;
    if (hasNoError) {
      const payload = {
        ...userDetails,
      };
      try {
        let response = await axios.post(
          `${config.apiPrefix}/jobapplications`,
          payload,
        );
        if (response.data.id) {
          response = await axios.post(`${config.apiPrefix}/sendmail`, {
            to: 'noelbjohn@gmail.com',
            name,
            email,
            number,
            subject: 'Job Application',
            message: `PFA resume for ${name} applying for post of ${jobPost} with ${experienceInYears &&
              `${experienceInYears} year(s)`}${experienceInMonths &&
              ` and ${experienceInMonths} months`} of experience`,
            resume,
            resumeFilename,
          });
          const { status } = response.data;
          if (status === 'success') {
            this.setState({
              ...this.getInitialState(),
            });
            this.setSnackbarParams(true, 'Message sent successfully');
          } else {
            this.setSnackbarParams(
              true,
              "Couldn't send email!! Try again after sometime",
            );
          }
        } else {
          this.setSnackbarParams(
            true,
            "Couldn't send email!! Try again after sometime",
          );
        }
      } catch (error) {
        this.setSnackbarParams(
          true,
          "Couldn't send email!! Try again after sometime",
        );
      }
    }
  };

  render() {
    const {
      careers,
      indexOfCareer,
      isSnackBarOpen,
      fetchingCareerDetails,
      openPopper,
      anchorEl,
      applyClicked,
      sendingMail,
      userDetails,
      snackbarMessage,
    } = this.state;
    const {
      name,
      email,
      number,
      experienceInMonths,
      experienceInYears,
      jobPost,
      resume,
    } = userDetails;
    const careerIndexesToDisplay = [
      indexOfCareer,
      indexOfCareer + 1,
      indexOfCareer + 2,
    ];
    const errorText = 'This field is required';
    const hasNoError =
      name &&
      email &&
      number &&
      (experienceInYears || experienceInMonths) &&
      jobPost &&
      resume;
    const disableAndShowProgress = sendingMail && applyClicked && hasNoError;
    return (
      <>
        <Grow in timeout={1500}>
          <Card className="full-height overflow-auto">
            <Grid container>
              <Grid item lg={10} className="full-height">
                <Card className="full-height">
                  <Typography variant="h4" align="center" color="primary">
                    Why YSC?
                  </Typography>
                  <CardContent>
                    <p>
                      Our success comes from our employees. We are consciously
                      growing. To facilitate our expansion program, YSC is
                      looking for top quality, dynamic, talented engineering
                      professionals at various levels. We offer outstanding
                      challenges and career prospects. YSC is the finest option
                      to begin your career. We adopt graduates and diploma
                      holders with high end track record as trainees. We have
                      effective tools, training programs and mentors to set you
                      in the right direction. We’ve also got the projects to
                      keep you learning and growing as your skills sharpen. Our
                      employee selection decisions are based on merit,
                      qualifications, and abilities. YSC does not discriminate
                      opportunities on the basis of race, colour, religion,
                      gender and age.
                    </p>
                    <p>
                      In YSC every moment of the work will live with you. We
                      care for your career development and enable you to grow
                      with our business. We offer you freedom of expression,
                      where each and every best performance will gain
                      appreciation and reward. At YSC the hierarchy will step
                      down to you, to encourage you, to horn your skills and to
                      make you even better. Individuals from diversified
                      cultural, educational and geographic backgrounds
                      constitute YSC but what unites us is our single minded
                      focus on continuous improvement. Our team leaders believe
                      in creating more leaders and not followers. They nurture
                      your talents to make you one among them and for sure, not
                      one below them. Definitely you are not going to be one of
                      the faceless people in a pool of engineering
                      professionals. But instead, new vistas, which enhance your
                      intellectual growth together with your emotional and
                      personal wellbeing, will be opened up towards you. YSC has
                      an employee friendly Human Resource Policy, which is far
                      beyond the statutory requirements of leaves, holidays and
                      overtime payment. Our polices help our employees to
                      balance their work and life. We ensure the employee social
                      security by enrolling them into various schemes like the
                      Provident Fund, ESI, Superannuation schemes and further
                      the employee will be enrolled into Medical Insurance
                      covering the employee and family, and also to an Accident
                      and Life Insurance Scheme covering the employee. Our
                      compensation structure covers all components including
                      House Rent Allowance, Transport allowance, Leave Travel
                      Allowance, Food/meals Coupon and Medical Reimbursement,
                      Uniform allowance. In addition, we also include
                      performance-linked components at various levels.
                    </p>
                  </CardContent>
                  <CardActions className="justify-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.togglePopper}
                    >
                      Apply for a job
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid lg={2}>
                <Card className="full-height">
                  <Typography variant="h4" align="center" color="primary">
                    Testimonials
                  </Typography>
                  <CardContent>
                    <p>
                      “Working in YSC has opened up a whole new dimension of
                      knowledge sharing. The working environment in YSC
                      generates the best out of me and motivates me to come up
                      with problem solving solutions. It is a great feeling to
                      realize that what I do is recognized, create value and
                      significance.”
                      <b>- JaySankar (Design Engineer Trainee)</b>
                    </p>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={12}>
                <Card>
                  <Typography variant="h4" align="center" color="primary">
                    Current Openings
                  </Typography>
                  <CardContent>
                    {careers.length > 0 && (
                      <Grid container>
                        <Grid item lg={1}>
                          <IconButton
                            color="primary"
                            style={{
                              padding: '0px',
                            }}
                            onClick={() => {
                              this.handleArrowClick('left');
                            }}
                          >
                            <Left
                              style={{
                                fontSize: '50px',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item lg={10}>
                          <Grid container>
                            {careers
                              .filter((career, index) =>
                                careerIndexesToDisplay.includes(index),
                              )
                              .map(career => (
                                <Grid item lg={4}>
                                  <Card className="full-height full-width">
                                    <CardContent>
                                      {`${career.label} `}
                                      <a
                                        href={`http://localhost:1337${career.link.url}`}
                                        without
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        Read more
                                      </a>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                        <Grid item lg={1}>
                          <IconButton
                            color="primary"
                            style={{
                              padding: '0px',
                            }}
                            onClick={() => {
                              this.handleArrowClick('right');
                            }}
                          >
                            <Right
                              style={{
                                fontSize: '50px',
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                    {fetchingCareerDetails && (
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grow>
        <Popper
          open={openPopper}
          anchorEl={anchorEl}
          placement="top"
          style={{
            position: 'fixed',
            left: '25%',
            right: '25%',
            width: '50%',
          }}
        >
          <Card>
            <CardContent>
              <Grid container>
                <Grid item lg={12}>
                  <Grid container>
                    <Grid item lg={6} className="textfield-margins">
                      <TextField
                        disabled={disableAndShowProgress}
                        label="Your Name"
                        error={applyClicked && !name}
                        helperText={applyClicked && !name && errorText}
                        required
                        fullWidth
                        value={name}
                        onChange={this.onChange('userDetails.name')}
                      />
                    </Grid>
                    <Grid item lg={6} className="textfield-margins">
                      <TextField
                        disabled={disableAndShowProgress}
                        label="Job Post"
                        error={applyClicked && !jobPost}
                        helperText={applyClicked && !jobPost && errorText}
                        required
                        fullWidth
                        value={jobPost}
                        onChange={this.onChange('userDetails.jobPost')}
                      />
                    </Grid>
                    <Grid item lg={6} className="textfield-margins">
                      <TextField
                        disabled={disableAndShowProgress}
                        label="Your email"
                        error={applyClicked && !email}
                        helperText={applyClicked && !email && errorText}
                        required
                        fullWidth
                        type="email"
                        value={email}
                        onChange={this.onChange('userDetails.email')}
                      />
                    </Grid>
                    <Grid item lg={6} className="textfield-margins">
                      <TextField
                        disabled={disableAndShowProgress}
                        label="Your phone number"
                        fullWidth
                        required
                        error={applyClicked && !number}
                        type="number"
                        value={number}
                        onChange={this.onChange('userDetails.number')}
                      />
                    </Grid>
                    <Grid item lg={6} className="textfield-margins">
                      <fieldset>
                        <legend>Experience*</legend>
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Number of years"
                          fullWidth
                          type="number"
                          value={experienceInYears}
                          onChange={this.onChange(
                            'userDetails.experienceInYears',
                          )}
                        />
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Number of months"
                          fullWidth
                          type="number"
                          value={experienceInMonths}
                          onChange={this.onChange(
                            'userDetails.experienceInMonths',
                          )}
                        />
                      </fieldset>
                    </Grid>
                    <Grid item lg={6} className="textfield-margins">
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={event => {
                          event.persist();
                          if (event.target.files.length > 0) {
                            const file = event.target.files[0];
                            userDetails.resumeFilename = file.name;
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              userDetails.resume = reader.result;
                              this.setState({
                                userDetails,
                              });
                            };
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} className="textfield-margins">
                      {disableAndShowProgress ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={this.onSend}
                        >
                          Apply
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Popper>
        <Snackbar
          open={isSnackBarOpen}
          message={snackbarMessage}
          onClose={() => {
            this.setSnackbarParams(false);
          }}
          autoHideDuration={3000}
          ContentProps={{
            className: 'snackbar-content',
          }}
          action={[
            <IconButton
              onClick={() => {
                this.setSnackbarParams(false);
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}
