import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Grid,
  Grow,
  Snackbar,
  IconButton,
  CardHeader,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import config from '../config.json';

export default class Download extends Component {
  constructor() {
    super();
    this.state = {
      downloads: [],
      isSnackBarOpen: false,
      snackbarMessage: '',
      fetchingData: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({
        fetchingData: true,
      });
      const typeOfDownloads = ['normal', 'press', 'technical'];
      const downloadResponse = await axios.get(`${config.apiPrefix}/downloads`);
      const downloads = downloadResponse.data.filter(download =>
        typeOfDownloads.includes(download.type),
      );
      this.setState({
        downloads,
      });
    } catch (error) {
      this.setSnackbarParams(true);
    }
    this.setState({
      fetchingData: false,
    });
  }

  onChipClick = url => {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.target = '_blank';
    anchorElement.click();
    anchorElement.remove();
  };

  setSnackbarParams = isSnackBarOpen => {
    this.setState({
      isSnackBarOpen,
      snackbarMessage: "Couldn't fetch download details!!",
    });
  };

  render() {
    const {
      downloads,
      snackbarMessage,
      isSnackBarOpen,
      fetchingData,
    } = this.state;
    const normalDownloads = downloads.filter(
      download => download.type === 'normal',
    );
    const pressDownloads = downloads.filter(
      download => download.type === 'press',
    );
    const technicalDownloads = downloads.filter(
      download => download.type === 'technical',
    );
    return (
      <>
        <Grow in timeout={1500}>
          <Grid container>
            {!fetchingData && (
              <>
                <Grid item lg={4}>
                  <Card>
                    <CardHeader title="General" />
                    <CardContent>
                      {normalDownloads.map(download => (
                        <Chip
                          onClick={() =>
                            this.onChipClick(
                              `http://localhost:1337${download.link.url}`,
                            )
                          }
                          label={download.label}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4}>
                  <Card>
                    <CardHeader title="Press Releases" />
                    <CardContent>
                      {pressDownloads.map(download => (
                        <Chip
                          onClick={() =>
                            this.onChipClick(
                              `http://localhost:1337${download.link.url}`,
                            )
                          }
                          label={download.label}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4}>
                  <Card>
                    <CardHeader title="Technical" />
                    <CardContent>
                      {technicalDownloads.map(download => (
                        <Chip
                          onClick={() =>
                            this.onChipClick(
                              `http://localhost:1337${download.link.url}`,
                            )
                          }
                          label={download.label}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
            {fetchingData && (
              <Grid
                item
                lg={12}
                style={{
                  textAlign: 'center',
                }}
              >
                >
                <CircularProgress />
              </Grid>
            )}
            <Grid lg={12}>
              <Card>
                <CardHeader title="Data Privacy & Security Policy" />
                <CardContent>
                  <p>
                    The all time policy of YSC is to protect its network against
                    any unauthorized access, use, corruption, disclosure, and
                    distribution of confidential information entrusted to us by
                    our clients. We undertake that, we shall not utilize any
                    confidential information other than that required for the
                    successful completion of the specific Project.
                  </p>
                  <p>
                    Our strategy on security of electronic networks emphasizes
                    on the nature of confidentiality and sensitivity. Any
                    interception or utilization of communication or stored data
                    by unauthorised system is prevented by the intact and fool
                    proof system which is in place and embedded in our whole
                    System. All events which threaten security are accounted,
                    not just those with malicious intent because threats such as
                    environmental incidents or human errors which disrupt the
                    network are potentially as costly as malicious attacks. Our
                    policy specifies and ensures the procedures for proper
                    disposal of unneeded or out- dated records and also for
                    protecting data when changes, up gradation, or replacement
                    of servers, computers or other storage media are in place.
                    The Storage devices have password controls together with
                    firewalls shield, intrusion detection and application level
                    controls. Attack on DNS servers is dealt with DNS protocols
                    and that on the routing system is defended with more
                    specific security functions like authentication, integrity,
                    and encryption. Threat on installed software has been
                    defended by most trusted and updated anti-virus software.
                    Networks (VPN) use SSL and IP sec to enable communications
                    to run over insecure Internet and open channels while
                    maintaining a given security level. The risks of natural
                    disasters ( storms, floods, fire or earthquakes, etc) are
                    covered by adequate back-up servers at multiple locations in
                    different parts of the Earth.
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grow>
        <Snackbar
          open={isSnackBarOpen}
          message={snackbarMessage}
          onClose={() => {
            this.setSnackbarParams(false);
          }}
          autoHideDuration={3000}
          ContentProps={{
            className: 'snackbar-content',
          }}
          action={[
            <IconButton
              onClick={() => {
                this.setSnackbarParams(false);
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}
