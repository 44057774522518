import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Grow,
  Divider,
  Snackbar,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/icons/List';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import config from '../config.json';

export default class Enquiry extends Component {
  constructor() {
    super();
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    userDetails: {
      companyName: '',
      name: '',
      email: '',
      number: '',
    },
    messageDetails: {
      subject: '',
      message: '',
    },
    isDialogOpen: false,
    sendClicked: false,
    isSnackBarOpen: false,
    snackbarMessage: '',
    sendingMail: false,
  });

  setSnackbarParams = (isSnackBarOpen, snackbarMessage = '') => {
    this.setState({
      isSnackBarOpen,
      snackbarMessage,
      sendingMail: false,
      sendClicked: false,
    });
  };

  onSend = async () => {
    this.setState({
      sendClicked: true,
      sendingMail: true,
    });
    const { userDetails, messageDetails } = this.state;
    const hasUserRequiredFields = userDetails.companyName && userDetails.email && userDetails.name;
    const hasMessageRequiredFields = messageDetails.subject && messageDetails.message;
    if (hasMessageRequiredFields && hasUserRequiredFields) {
      const payload = {
        to: 'test@yscindia.com',
        ...userDetails,
        ...messageDetails,
      };
      try {
        let response = await axios.post(`${config.apiPrefix}/enquiries`, {
          ...userDetails,
          ...messageDetails,
        });
        if (response.data.id) {
          response = await axios.post(`${config.apiPrefix}/sendmail`, payload);
          const { status } = response.data;
          if (status === 'success') {
            this.setState({
              ...this.getInitialState(),
            });
            this.setSnackbarParams(true, 'Message sent successfully');
          } else {
            this.setSnackbarParams(
              true,
              "Couldn't send email!! Try again after sometime",
            );
          }
        } else {
          this.setSnackbarParams(
            true,
            "Couldn't send email!! Try again after sometime",
          );
        }
      } catch (error) {
        this.setSnackbarParams(
          true,
          "Couldn't send email!! Try again after sometime",
        );
      }
    }
  };

  openDialog = () => {
    this.setState(prevState => ({
      isDialogOpen: !prevState.isDialogOpen,
    }));
  };

  onChange = stateKey => (event) => {
    const parentStateKey = stateKey.split('.')[0];
    const childStateKey = stateKey.split('.')[1];
    const currentState = this.state;
    currentState[parentStateKey][childStateKey] = event.target.value;
    this.setState({
      ...currentState,
      sendClicked: false,
    });
  };

  render() {
    const {
      userDetails,
      messageDetails,
      isDialogOpen,
      sendClicked,
      isSnackBarOpen,
      snackbarMessage,
      sendingMail,
    } = this.state;
    const errorText = 'This field is required';
    const hasUserRequiredFields = userDetails.companyName && userDetails.email && userDetails.name;
    const hasMessageRequiredFields = messageDetails.subject && messageDetails.message;
    const hasNoError = hasUserRequiredFields && hasMessageRequiredFields;
    const disableAndShowProgress = sendingMail && sendClicked && hasNoError;
    return (
      <>
        <Grow in timeout={1500}>
          <Box>
            <Typography variant="h4" align="center" color="primary">
              Business Enquiry
            </Typography>
            <Card className="enquiry-card-root">
              <CardContent className="enquiry-card-content-root">
                <Grid container>
                  <Grid item lg={9}>
                    <Grid container>
                      <Grid item lg={6} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Company Name"
                          error={sendClicked && !userDetails.companyName}
                          helperText={
                            sendClicked && !userDetails.companyName && errorText
                          }
                          required
                          fullWidth
                          value={userDetails.companyName}
                          onChange={this.onChange('userDetails.companyName')}
                        />
                      </Grid>
                      <Grid item lg={6} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Your Name"
                          error={sendClicked && !userDetails.name}
                          helperText={
                            sendClicked && !userDetails.name && errorText
                          }
                          required
                          fullWidth
                          value={userDetails.name}
                          onChange={this.onChange('userDetails.name')}
                        />
                      </Grid>
                      <Grid item lg={6} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Your email"
                          error={sendClicked && !userDetails.email}
                          helperText={
                            sendClicked && !userDetails.email && errorText
                          }
                          required
                          fullWidth
                          type="email"
                          value={userDetails.email}
                          onChange={this.onChange('userDetails.email')}
                        />
                      </Grid>
                      <Grid item lg={6} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Phone number"
                          fullWidth
                          type="number"
                          value={userDetails.number}
                          onChange={this.onChange('userDetails.number')}
                        />
                      </Grid>
                      <Grid item lg={12} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Subject"
                          error={sendClicked && !messageDetails.subject}
                          helperText={
                            sendClicked && !messageDetails.subject && errorText
                          }
                          fullWidth
                          required
                          value={messageDetails.subject}
                          onChange={this.onChange('messageDetails.subject')}
                        />
                      </Grid>
                      <Grid item lg={12} className="textfield-margins">
                        <TextField
                          disabled={disableAndShowProgress}
                          label="Your message"
                          fullWidth
                          multiline
                          required
                          error={sendClicked && !messageDetails.message}
                          helperText={
                            sendClicked && !messageDetails.message && errorText
                          }
                          rows={5}
                          value={messageDetails.message}
                          onChange={this.onChange('messageDetails.message')}
                        />
                      </Grid>
                      <Grid item lg={12} className="textfield-margins">
                        {disableAndShowProgress ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={this.onSend}
                          >
                            Send
                            <SendIcon />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box
              style={{
                position: 'fixed',
                bottom: '12%',
                right: '5%',
              }}
            >
              {isDialogOpen && (
                <Grid container>
                  <Grid
                    item
                    lg={3}
                    style={{
                      right: '1%',
                      bottom: '10%',
                      position: 'fixed',
                    }}
                  >
                    <Card
                      style={{
                        padding: '1%',
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          fontSize: '21px',
                        }}
                      >
                        Business Enquiry with YSC
                      </Typography>
                      <Divider />
                      <p className="justify-content">
                        YSC invites you to explore possible business
                        opportunities. Please browse for the services that we
                        offer. We would be very glad to discuss ways in which we
                        can deliver value to you for your Engineering needs. If
                        you have not found the services, technology, or the
                        solutions that you are looking for, you can communicate
                        your requirements to us. We are sure we will be able to
                        provide you with the best Engineering Services that suit
                        your needs with the help of our partners.
                      </p>
                      <p className="justify-content">
                        Our Business Development Manager will discuss your
                        specific requirements in detail. Subsequently, our
                        technical and domain expert team will conduct a
                        technical assessment and feasibility study to suggest
                        the best possible solution for you. We do get assistance
                        from our partners located globally to meet such special
                        requirements of our clients.
                      </p>
                      <p className="justify-content">
                        Submitting your query will allow us to contact you
                        regarding your enquiry. We wish to partner you in
                        prosperity and together, lets make things happen for the
                        better.
                      </p>
                      <b className="justify-content">
                        All submitted information will be kept confidential.
                      </b>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={this.openDialog}
                style={{
                  position: 'fixed',
                  bottom: '2%',
                  right: '5%',
                }}
              >
                <List fontSize="large" />
                Business Enquiry with YSC
              </Button>
            </Box>
          </Box>
        </Grow>
        <Snackbar
          open={isSnackBarOpen}
          message={snackbarMessage}
          onClose={() => {
            this.setSnackbarParams(false);
          }}
          autoHideDuration={3000}
          ContentProps={{
            className: 'snackbar-content',
          }}
          action={[
            <IconButton
              onClick={() => {
                this.setSnackbarParams(false);
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}
