import React from 'react';
import { Card, CardContent, Zoom, Grid } from '@material-ui/core';

const Competence = () => (
  <Grid container className="route-component-root">
    <Grid item lg={12}>
      <Zoom in timeout={700}>
        <Card elevation={7} className="resource-root-card">
          <CardContent>
            <p>
              Operational Excellence differentiates our business model. We
              attribute relationship-based approach hence we could maintain
              long-term business relationships. In addition to maintaining our
              existing clients, we could expand the client list in the
              competitive market by working in tandem with our clients rather
              than as adversaries. SAT and knowledge based Planning combined
              with Cause & Effect Analysis executed from the grass root level
              helped us to minimize errors , identify and overcome bottlenecks ,
              leading to sustainable growth. Resources not being a limiting
              factor, scalability to demand is a feasible factor on short
              notice.
            </p>
            <p>
              More and more values are being added up to our deliverables by
              deploying the innovative and proven methodology in the
              construction & fabrication arena .We even treat a repeated order
              as a new challenge by setting revised goals thereby optimizing the
              client’s resources. This in turn reinforces the satisfaction of
              services, value of our business model thus ensuring continued
              growth and success. Being part of a major Engineering Procurement
              and Construction Company, we could foresee the viability of
              fabrication and erection, material availability and it’s effective
              utilization, cost cut-down techniques along with current
              development and internally developed design safety measures.
            </p>
            <p>
              YSC can help you reduce your environmental footprint by compiling
              all relevant safety codes and standards. Our engineers have
              remarkable exposure to various international codes and standards
              along with client specific requirements.
            </p>
            <p>
              Being a technology based company, employees are our prime asset.
              Their expertise and capabilities take us to the winning platform.
              Our employee friendly policy and ‘Destination work’ atmosphere
              help us to retain our highly motivated, qualified, skilled and
              experienced professionals who deliver their services with utmost
              perfection and precision for the complete satisfaction of our
              clients
            </p>
          </CardContent>
        </Card>
      </Zoom>
    </Grid>
  </Grid>
);
export default Competence;
