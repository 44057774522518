import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  ListItemText,
  List,
  ListItem,
  Divider,
  Snackbar,
  IconButton,
  CircularProgress,
  CardHeader,
  CardMedia,
  Fade,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import config from '../config.json';

export default class Projects extends Component {
  constructor() {
    super();
    this.state = {
      projects: [],
      downloads: [],
      isSnackBarOpen: false,
      snackbarMessage: '',
      fetchingData: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({
        fetchingData: true,
      });
      const typeOfDownloads = ['ongoing_projects', 'completed_projects'];
      const projectResponse = await axios.get(`${config.apiPrefix}/projects`);
      const downloadResponse = await axios.get(`${config.apiPrefix}/downloads`);
      const downloads = downloadResponse.data.filter((download) =>
        typeOfDownloads.includes(download.type),
      );
      this.setState({
        projects: projectResponse.data,
        downloads,
      });
    } catch (error) {
      this.setSnackbarParams(true);
    }
    this.setState({
      fetchingData: false,
    });
  }

  setSnackbarParams = (isSnackBarOpen) => {
    this.setState({
      isSnackBarOpen,
      snackbarMessage: "Couldn't fetch project details!!",
    });
  };

  render() {
    const {
      projects,
      snackbarMessage,
      isSnackBarOpen,
      downloads,
      fetchingData,
    } = this.state;
    return (
      <>
        <Fade in timeout={1500}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item lg={12}>
                  YSC continues to be the prime choice of Saudi Arabia’s
                  Engineering and Construction industrial sector. Below links
                  gives the project summary of the major works executed by Yanbu
                  Steel Company.
                </Grid>
                <Grid
                  item
                  lg={4}
                  style={{
                    marginTop: '3%',
                  }}
                >
                  <Card>
                    <CardHeader title="Major ongoing Projects are" />
                    <CardContent>
                      <List>
                        {projects.map((project, index) => (
                          <>
                            <ListItem>
                              <ListItemText
                                primary={project.name}
                                secondary={
                                  <Typography
                                    component="div"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {project.place}
                                  </Typography>
                                }
                              />
                            </ListItem>
                            {index !== projects.length - 1 && (
                              <Divider variant="inset" component="li" />
                            )}
                          </>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={8}
                  style={{
                    marginTop: '3%',
                  }}
                >
                  <Card>
                    <CardHeader title="Project Summary" />
                    <CardContent>
                      <Grid container>
                        {downloads.map(download => (
                          <Grid item lg={6}>
                            <Card>
                              <CardHeader title={download.label} />
                              <CardMedia
                                className="card-media"
                                image={`http://localhost:1337${download.thumbnail.url}`}
                              />
                              <CardContent>
                                <a
                                  href={`http://localhost:1337${download.link.url}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Download project list
                                </a>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      {fetchingData && (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fade>
        <Snackbar
          open={isSnackBarOpen}
          message={snackbarMessage}
          onClose={() => {
            this.setSnackbarParams(false);
          }}
          autoHideDuration={3000}
          ContentProps={{
            className: 'snackbar-content',
          }}
          action={[
            <IconButton
              onClick={() => {
                this.setSnackbarParams(false);
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}
