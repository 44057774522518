import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Slide,
  Grid,
  Grow,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../config.json';

class Resources extends Component {
  constructor() {
    super();
    this.state = {
      softwareCapabilityPanelIsOpen: false,
      thirdPartySoftwarePanelIsOpen: false,
      inHouseSoftwarePanelIsOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('wheel', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.onScroll);
  }

  onScroll = (event) => {
    const hasScrolledDown = event.wheelDeltaY < 0;
    this.setState({
      softwareCapabilityPanelIsOpen: hasScrolledDown,
      thirdPartySoftwarePanelIsOpen: hasScrolledDown,
      inHouseSoftwarePanelIsOpen: hasScrolledDown,
    });
  };

  togglePanel = (togglePanelStateKey) => {
    this.setState(prevState => ({
      [togglePanelStateKey]: !prevState[togglePanelStateKey],
    }));
  };

  render() {
    const {
      softwareCapabilityPanelIsOpen,
      thirdPartySoftwarePanelIsOpen,
      inHouseSoftwarePanelIsOpen,
    } = this.state;
    return (
      <div>
        <Grid container className="route-component-root">
          <Grid item lg={12}>
            <Slide in timeout={700}>
              <Card elevation={7} className="resource-root-card">
                <CardContent>
                  <p>
                    Our strength is the inherited technology and engineering
                    practices evolved through a century from our parent company
                    and also the current up gradation from diversified group of
                    companies under the Corporate umbrella, to which YSC
                    belongs. Besides in-house developed applications, YSC uses a
                    number of standard software including legitimate CAD,
                    modelling, design and analysis tools which ensures efficient
                    and cost effective designing techniques. Project associate
                    agreement with reputed companies of specialized services,
                    facilitate YSC to serve along the lines of vivid demand.
                    Office in India occupies about 5000 Sq.ft of built up
                    centrally air conditioned area with fully networked 65
                    workstations, meeting rooms, library and archives.
                  </p>
                  <p>
                    Our engineering talent pool has significant exposure to
                    various industries, tools, platforms, and project management
                    methodologies. YSC has the infrastructure to produce 50000
                    Engineering man-hours and 120000 drafting man-hours
                    annually. Beyond software and hardware, we have globally
                    experienced engineering professionals and experts in India.
                    Automated Project Management tools ensure seamless planning,
                    scheduling, workflow management and progress reporting.
                    Fully equipped Technical Document Centre (TDC) consists of
                    specialized human resources, supporting hardware like
                    Drawing plotter, A3 laser printers, Digital copiers & colour
                    scanners, High end servers, backup and network security
                    systems and audio & video conferencing facility. FTP server
                    makes possible the bulk transfer of documents.
                  </p>
                  <p>
                    YSC holds its office in Thejaswini, Technopark,
                    Thiruvananthapuram with the state-of–the art Infrastructure
                    and dedicated connectivity. It is a dream work destination
                    of Asia, which provides a holistic environment to make
                    knowledge industry intrinsically competitive and of world
                    class. It offers robust and failsafe power and data
                    connectivity; contemporary support services, undisturbed
                    working hours. These unique confluence of advantages
                    facilitate YSC to function up to 24×365. Other geographical
                    advantages are propinquity of international airport and
                    luxury business hotels, availability of highly trained
                    technical manpower, stress free, easy paced quality life,
                    clean & green environment, less commuting time to office,
                    knowledge focused society. Proximity to tourists hotspots
                    enable week end relaxation combining work and leisure, all
                    of which offer a unique experience.
                  </p>
                </CardContent>
              </Card>
            </Slide>
          </Grid>
          <Grid item lg={12}>
            <Grid container>
              <Grid item lg={4}>
                <Grow in timeout={1000}>
                  <ExpansionPanel
                    expanded={softwareCapabilityPanelIsOpen}
                    classes={{
                      root: 'side-card',
                      expanded: 'side-card',
                    }}
                    className="side-card"
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => {
                        this.togglePanel('softwareCapabilityPanelIsOpen');
                      }}
                    >
                      Software Capabilities
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        YSC is well equipped with the latest engineering tools
                        for accurate and timely completion of projects. We pay
                        Elevated importance for the development of customized
                        software along with the addition of third party
                        software.
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grow>
              </Grid>
              <Grid item lg={4}>
                <Grow in timeout={1000}>
                  <ExpansionPanel
                    expanded={thirdPartySoftwarePanelIsOpen}
                    classes={{
                      root: 'side-card',
                      expanded: 'side-card',
                    }}
                    className="side-card"
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => {
                        this.togglePanel('thirdPartySoftwarePanelIsOpen');
                      }}
                    >
                      Third Party Software
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        {config.thirdPartySoftwares.map(software => (
                          <Chip
                            key={software}
                            color="primary"
                            size="small"
                            label={software}
                          />
                        ))}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grow>
              </Grid>
              <Grid item lg={4}>
                <Grow in timeout={1000}>
                  <ExpansionPanel
                    expanded={inHouseSoftwarePanelIsOpen}
                    classes={{
                      root: 'side-card',
                      expanded: 'side-card',
                    }}
                    className="side-card"
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => {
                        this.togglePanel('inHouseSoftwarePanelIsOpen');
                      }}
                    >
                      In-House Softwares
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        {config.inHouseSoftwares.map(software => (
                          <Tooltip
                            key={software}
                            classes={{
                              tooltip: 'tool-tip',
                            }}
                            title={software.split('(')[1].replace(')', '')}
                          >
                            <Chip
                              color="primary"
                              size="small"
                              label={software.split('(')[0]}
                            />
                          </Tooltip>
                        ))}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Resources;
