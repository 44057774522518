import React from 'react';
import { Card, CardContent, Grow } from '@material-ui/core';

const About = () => (
  <Grow in timeout={700}>
    <Card elevation={7} className="route-component-root About-root-card">
      <CardContent>
        <p>
          YSC Engineering Services Pvt Ltd , the subsidiary of the major world
          class EPC (Engineering, Procurement and Construction) company M/s
          Yanbu Steel Company, KSA (YSC), serves industries like oil & gas,
          petrochemical and water. Yanbu Steel Company was formerly known as PDM
          Saudi Arabia, set up in 1982, as a subsidiary of PDM USA, which was
          reconstituted as Yanbu Steel in 2000, as its shares were acquired by
          the MIDROC Group. Apart from the construction and erection facility at
          different project locations, the facilities at Yanbu include twenty
          thousand square meters of fully equipped covered workshops,
          complimented by a skilled multinational workforce of over two thousand
          personnel, located in the RED sea, industrial city of Yanbu, KSA.
        </p>
        <p>
          YSC Engineering Services Pvt. Ltd was formed in the year 2005 as a
          subsidiary of Yanbu Steel Company KSA. YSC Engineering Services Pvt
          Ltd is located at Technopark- Thiruvananthapuram, Kerala, India’s most
          promising IT Destination.
        </p>
        <p>
          Our core competency includes design, detail engineering, project
          management & procurement assistance for turnkey projects involving
          storage tanks, pressure vessels, process equipment, piping, RCC and
          steel buildings, steel structures, pipe racks, multi storied
          buildings, complexes & civil foundations for chemical, Oil & Gas,
          fertilizer, Power, Water and Cement Industries. We undertake design,
          detailing and modelling of industrial and residential projects. Our
          high quality resources at Technopark in close synergy with the
          services provided by our holding company YSC, KSA enhances the design
          and engineering capability of our company multifold. We forever, stand
          for prompt, cost effective quality services.
        </p>
      </CardContent>
    </Card>
  </Grow>
);

export default About;
