import React from 'react';
import {
  Box,
  CardContent,
  Typography,
  Card,
  Grid,
  Slide,
} from '@material-ui/core';
import Room from '@material-ui/icons/Room';
import Mail from '@material-ui/icons/Mail';
import Phone from '@material-ui/icons/Phone';
import Fax from '@material-ui/icons/Print';
import Website from '@material-ui/icons/Public';
import Person from '@material-ui/icons/AccountBox';
import Map from '@material-ui/icons/Map';
import config from '../config.json';

const Contact = () => (
  <Box>
    <Typography color="primary" variant="h4" align="center">
      Contact Us
    </Typography>
    <Grid container>
      <Grid item lg={6} className="contact-card-content-root">
        <Slide in timeout={1000} direction="right">
          <Card>
            <Typography variant="h4" align="center">
              India
            </Typography>
            <CardContent>
              <Grid container>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Person fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    Operation Manager
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Room fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    YSC Engineering Services Pvt Ltd. B2, C-3, Thejaswini
                    Technopark, Thiruvananthapuram, Kerala 695581
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Mail fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    mail@yscindia.com
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Phone fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    +91 471 4098888, 4098819
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Fax fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    +91 471 3042301
                  </Typography>
                </Grid>
                <Grid lg={12}>
                  <Typography color="primary" align="center">
                    <Map fontSize="large" />
                  </Typography>
                  <iframe
                    src={config.googleMapForYSCIndia}
                    width={600}
                    height={450}
                    className="map-iframe"
                    title="Google map for YSC India"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Slide>
      </Grid>
      <Grid item lg={6} className="contact-card-content-root">
        <Slide in timeout={1000} direction="left">
          <Card>
            <Typography variant="h4" align="center">
              Saudi Arabia
            </Typography>
            <CardContent>
              <Grid container>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Person fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    Sales & Marketing Manager
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Room fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    7th Street , Light Industrial Park Yanbu Industrial City,
                    51000 Kingdom of Saudi Arabia
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Mail fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    info@yanbusteel.com
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Phone fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    +966 4 396 1171
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography color="primary" align="center">
                    <Website fontSize="large" />
                  </Typography>
                  <Typography align="center" variant="h6">
                    www.yanbusteel.com
                  </Typography>
                </Grid>
                <Grid lg={12}>
                  <Typography color="primary" align="center">
                    <Map fontSize="large" />
                  </Typography>
                  <iframe
                    src={config.googleMapForYSCSaudi}
                    width={600}
                    height={450}
                    className="map-iframe"
                    title="Google map for YSC Saudi Arabia"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Slide>
      </Grid>
    </Grid>
  </Box>
);

export default Contact;
