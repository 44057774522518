import React, { Component } from 'react';
import { AppBar, Box, IconButton, MenuItem, Menu } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreVert';
import Logo from '../assets/images/logo.gif';
import config from '../config.json';

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: 'YSC Engineering Services Pvt Ltd.',
      mobileMoreAnchorEl: null,
    };
  }

  setMobileMoreAnchorEl = event => {
    this.setState({
      mobileMoreAnchorEl: event && event.currentTarget,
    });
  };

  setHeading = heading => event => {
    this.setState({
      heading,
    });
    this.setMobileMoreAnchorEl(null);
  };

  render() {
    const { heading, mobileMoreAnchorEl } = this.state;
    return (
      <AppBar className="navbar-root" position="sticky">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className="navbar-desktop"
        >
          <Box width={0.25}>
            <Link to="/" className="navbar-link">
              <img className="logo" alt="YSC logo" src={Logo} />
              <span className="navbar-company">
                YSC Engineering Services Pvt Ltd.
              </span>
            </Link>
          </Box>
          <Box
            width={0.75}
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
          >
            {config.links
              .filter(value => value !== 'Home')
              .map(link => (
                <Link
                  key={link}
                  to={`/${link.toLowerCase()}`}
                  className="navbar-link"
                >
                  {link}
                </Link>
              ))}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          height={1}
          className="navbar-mobile"
        >
          <h1>{heading}</h1>
          <IconButton onClick={this.setMobileMoreAnchorEl}>
            <MoreIcon className="more-icon" />
          </IconButton>
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!mobileMoreAnchorEl}
            onClose={() => {
              this.setMobileMoreAnchorEl(null);
            }}
          >
            {config.links.map(link => (
              <Link
                key={link}
                to={`${link === 'Home' ? '/' : `/${link.toLowerCase()}`}`}
                className="menu-item"
              >
                <MenuItem
                  onClick={this.setHeading(
                    link === 'Home'
                      ? 'YSC Engineering Services Pvt Ltd.'
                      : link,
                  )}
                >
                  {link}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Box>
      </AppBar>
    );
  }
}
