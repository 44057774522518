import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Slide,
  Grid,
  CardHeader,
  Divider,
  Typography,
  Fade,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Services = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container className="route-component-root">
      <Grid item lg={9}>
        <Grid container>
          <Grid item lg={4}>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="STATIC EQUIPMENT ENGINEERING" />
                  <CardContent>
                    <Typography variant="body1">
                      <b>FEED</b>
                    </Typography>
                    <Divider />
                    <Typography variant="body2">
                      Equipment Sizing, Datasheet Preparation & Material
                      Requisition, Bid Review
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      <b>PMC</b>
                    </Typography>
                    <Divider />
                    <Typography variant="body2">
                      Vendor document review, Detailed Engineering, Technical
                      Communication, Progress Chasing and expediting, Document
                      Flow control, Review of change Order, WPS and NDT
                      procedure
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      <b>Detailed Engineering Support</b>
                    </Typography>
                    <Divider />
                    <Typography variant="body2">
                      It is formulated to meet the engineering requirements of
                      equipment vendors or of fabricators. Deliverables include
                      Design Calculations, Detailed and fabrication shop
                      drawings, Inspection and testing Procedures, Material Take
                      Off, Procurement assistance of engineering materials and
                      goods
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="PROCESS ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      Sizing calculations for defining equipment design, Process
                      Flow Diagram, P&ID, Process Design Philosophy, Simulation
                      Study, Control and Shutdown Philosophy, Heat exchanger
                      thermal sizing and vibration analysis, Design of package
                      units, Start-up and maintenance Manual, HAZOP & Loss
                      Prevention Study
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
          </Grid>
          <Grid item lg={4}>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="PIPING ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      Piping Flexibility and Stress analysis, Terminal pipelines
                      and pigging systems, Plant 3D Model creation, Clash
                      review, Piping Layout drawing, Isometric Drawing, Support
                      location and detail Drawing, Material List including
                      specification for process, hydrocarbon, steam Deluge and
                      foam piping
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="INSTRUMENTATION ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      System Architecture Drawings, Instrument Data Sheets,
                      DCS/PLC, ESD engineering with I/O List, Specifications for
                      Field Instrument and control panel, JB and cable schedule,
                      Instrument Hook ups, Level Sketches, SCADA and RTU System
                      Engineering, Communication Network Design, Sizing of
                      Relief Valve, Control valve and orifice plate, Preparation
                      of Air and Power distribution scheme
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="ADD-ON SERVICES" />
                  <CardContent>
                    <Typography variant="body2">
                      Assistance for Recruiting Technical and Engineering
                      Professional, Technical Man power Supply. Feasibility
                      study of Engineering Projects, Cost Estimation. Dedicated
                      service is being offered through our associate companies
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
          </Grid>
          <Grid item lg={4}>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="STRUCTURAL, CIVIL ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      Design, Modeling & Detailing of industrial and residential
                      projects which includes RCC and Steel Structures, Pipe
                      racks, Chimney foundations, Tank foundations, multi-storey
                      buildings and complexes. We deliver detailed Fabrication
                      drawings for steel structures and Re-bar detailing of RCC
                      structures including Material Bill of Quantities
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="ROTATING EQUIPMENT ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      Equipment selection, Preparation of Procurement
                      Specification, Review of Technical Bid
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
            <Slide in timeout={1500}>
              <div>
                <Card elevation={5} className="resource-root-card">
                  <CardHeader title="ELECTRICAL ENGINEERING" />
                  <CardContent>
                    <Typography variant="body2">
                      Load Estimation Calculation and Single Line Power
                      Distribution Diagram, Equipment Specifications, Data sheet
                      & schedule of quantities for Electrical Equipments,
                      Lightning Protection & Earthing Layouts, Lighting Layouts
                      including Distribution Schedules, Cable Tray / Trench
                      Layouts, Earthing & Layouts Bulk MTO, Cable schedules and
                      Termination Schedules. Short Circuit Study & Relay Setting
                      recommendations, Impressed current CP Systems Design and
                      layout, Hazardous Area Classification Drawings.
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Slide>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3}>
        <Fade in timeout={1500}>
          <Card>
            <CardHeader title="Advantages" />
            <CardContent>
              <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Tailor-Made Services</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  YSC offers flawless services in line with client’s
                  requirement, quality standards and customized practices. YSC
                  can formulate a variety of service agreements, depending on
                  your specific requirements and infrastructure tailback.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Global Presence</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  YSC has the benefit of global presence through it’s parent
                  company and group.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    World Class Infrastructure
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  YSC operates from CMMI Level 4 assessed Technology Park.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Expandable Resources</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  YSC has multidiscipline engineering squad with vast available
                  ready infrastructure & human resources data bank for easy &
                  fast expansion according to project requirements.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    Contemporary Tools & Technology
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  YSC uses latest international standards & codes combined with
                  best software solutions that are handled ably by its team of
                  well trained and competent personnel.
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Client Care</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  Our service motto rests on three pillars, namely client
                  satisfaction, quality and promptness, which ensure the best
                  solution in cost saving. YSC has executed many confidential
                  projects with secrecy agreements and it has established
                  security measures to safeguard your know-how and methodology.
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </CardContent>
          </Card>
        </Fade>
      </Grid>
    </Grid>
  );
};

export default Services;
