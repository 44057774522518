import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavBar from './NavBar.jsx';
import Home from './Home.jsx';
import About from './About.jsx';
import Contact from './Contact.jsx';
import Download from './Download.jsx';
import Enquiry from './Enquiry.jsx';
import Competence from './Competence.jsx';
import Resources from './Resources.jsx';
import Services from './Services.jsx';
import Career from './Career.jsx';
import Projects from './Projects.jsx';
import Footer from './Footer.jsx';

const App = () => (
  <div className="height-100-percent App-root-div">
    <Router>
      <NavBar />
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/download" component={Download} />
      <Route exact path="/competence" component={Competence} />
      <Route exact path="/resources" component={Resources} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/enquiry" component={Enquiry} />
      <Footer />
    </Router>
  </div>
);

export default App;
