import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import Left from '@material-ui/icons/NavigateBefore';
import Right from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import config from '../config.json';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      clients: [],
      indexOfClientImage: 0,
      isSnackBarOpen: false,
      fetchingClientDetails: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${config.apiPrefix}/clients`);
      if (Array.isArray(response.data)) {
        this.setState({
          clients: response.data,
        });
      } else {
        this.setState({
          isSnackBarOpen: true,
        });
      }
    } catch (e) {
      this.setState({
        isSnackBarOpen: true,
      });
    } finally {
      this.setState({
        fetchingClientDetails: false,
      });
    }
  }

  handleArrowClick(direction) {
    let incrementValue = 0;
    const { clients } = this.state;
    let { indexOfClientImage } = this.state;
    const lengthofClients = clients.length;
    if (lengthofClients > 3) {
      if (direction === 'right') {
        incrementValue = 3;
      }
      if (direction === 'left') {
        incrementValue = -3;
      }
      indexOfClientImage += incrementValue;
      if (indexOfClientImage >= lengthofClients) {
        indexOfClientImage = 0;
      }
      if (indexOfClientImage < 0) {
        indexOfClientImage = lengthofClients - 1;
      }
    }
    this.setState({
      indexOfClientImage,
    });
  }

  handleSnackBarClose() {
    this.setState({
      isSnackBarOpen: false,
    });
  }

  render() {
    const {
      clients,
      indexOfClientImage,
      isSnackBarOpen,
      fetchingClientDetails,
    } = this.state;
    const clientIndexesToDisplay = [
      indexOfClientImage,
      indexOfClientImage + 1,
      indexOfClientImage + 2,
    ];
    return (
      <Grid
        alignItems="center"
        container
        className="home-root route-component-root"
      >
        <Grid item lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">Our Clients</Typography>
              <Divider />
              <Typography variant="caption">
                The high caliber performance of Yanbu Steel Company attracts the
                premier companies in petroleum and petrochemical industries and
                a result, Yanbu Steel Company is proved to have developed an
                extensive repeat business portfolio of major clients including:
              </Typography>
              {clients.length > 0 && (
                <Grid container>
                  <Grid item lg={1}>
                    <IconButton
                      color="primary"
                      style={{
                        padding: '0px',
                      }}
                      onClick={() => {
                        this.handleArrowClick('left');
                      }}
                    >
                      <Left
                        style={{
                          fontSize: '50px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item lg={10}>
                    <Grid container>
                      {clients
                        .filter((client, indexOfClient) => clientIndexesToDisplay
                          .includes(indexOfClient))
                        .map(client => (
                          <Grid item lg={4}>
                            <img
                              alt="enppi"
                              src={`${config.apiPrefix}${client.Image.url}`}
                              height="100%"
                              width="100%"
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item lg={1}>
                    <IconButton
                      color="primary"
                      style={{
                        padding: '0px',
                      }}
                      onClick={() => {
                        this.handleArrowClick('right');
                      }}
                    >
                      <Right
                        style={{
                          fontSize: '50px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {fetchingClientDetails && (
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Snackbar
          open={isSnackBarOpen}
          message="Couldn't fetch client details!!"
          onClose={() => {
            this.handleSnackBarClose();
          }}
          autoHideDuration={3000}
          ContentProps={{
            className: 'snackbar-content',
          }}
          action={[
            <IconButton
              onClick={() => {
                this.handleSnackBarClose();
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Grid>
    );
  }
}

export default Home;
