import React from 'react';
import { AppBar } from '@material-ui/core';

const Footer = () => (
  <AppBar position="sticky" className="footer">
    <span>&copy; Copyright YSC India Private Ltd.</span>
  </AppBar>
);

export default Footer;
